import { TextVariant } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import React from 'react';
import styled from 'styled-components';
import { CardVariant } from '@naf/cards';
import {
  ButtonGroup,
  Content,
  Cover,
  Description,
  Heading,
  HeadingBlock,
  MediaComponent,
  Step,
  Steps,
  Tag,
  Top,
  Wrapper,
} from './StyledFeaturedBenefitBlock';
import { FeaturedBenefitBlockTypeProps } from './FeaturedBenefitBlock';
import BenefitsList from '../../../benefits-list/BenefitsList';
import Timer from '../CountdownBlock/Timer';

export const HorizontalWithMedia: React.FC<FeaturedBenefitBlockTypeProps> = ({ block }) => {
  const {
    title,
    colorVariant,
    description,
    tag,
    primaryCta,
    secondaryCta,
    image,
    video,
    steps,
    membershipBenefits,
    illustration,
    countdownTo,
  } = block;

  return (
    <Wrapper $variant={colorVariant} $breakEarly={!!countdownTo}>
      <Content>
        {tag && <Tag $styleVariant={colorVariant}>{tag}</Tag>}
        <StyledTop>
          <Heading variant={TextVariant.Display}>
            <HeadingBlock value={title} />
          </Heading>
          {description && <Description>{description}</Description>}
        </StyledTop>
        {steps && (
          <Steps>
            {steps.map((step, i) => (
              <Step key={step._key} step={step} index={i + 1} variant={colorVariant} />
            ))}
          </Steps>
        )}
        <Bottom $breakEarly={!!countdownTo}>
          <ButtonGroup colorVariant={colorVariant} primaryCta={primaryCta} secondaryCta={secondaryCta}>
            {countdownTo && (
              <TimerWrapper>
                <Timer targetTime={countdownTo} />
              </TimerWrapper>
            )}
          </ButtonGroup>
          {membershipBenefits && membershipBenefits.length > 0 ? (
            <BenefitWrapper>
              <BenefitsList size={CardVariant.Standard} benefitItems={membershipBenefits} />
            </BenefitWrapper>
          ) : (
            <MediaWrapper>
              <MediaComponent video={video} image={image} />
            </MediaWrapper>
          )}
        </Bottom>
      </Content>
      {((image && !illustration) || video || membershipBenefits) && <Cover $breakEarly={!!countdownTo} />}
    </Wrapper>
  );
};

const StyledTop = styled(Top)`
  align-items: start;
  @media (max-width: ${breakpoints.m}) {
    width: 100%;
  }
`;

const Bottom = styled.div<{ $breakEarly?: boolean }>`
  display: flex;
  gap: ${spacing.space80};
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;

  @media (max-width: ${({ $breakEarly }) => ($breakEarly ? breakpoints.l : breakpoints.m)}) {
    flex-direction: column-reverse;
    gap: ${spacing.space40};
  }
`;

const BenefitWrapper = styled.div`
  flex-grow: 1;
  z-index: 3;
`;

const MediaWrapper = styled.div`
  flex-grow: 1;
  max-width: 736px;
  z-index: 3;
`;

const TimerWrapper = styled.div`
  margin-bottom: ${spacing.space24};
`;

import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@naf/theme';
import { FeaturedBenefitBlockType, LayoutVariant } from '../../../../../../types/featuredBenefitBlockType';

import { WithQnA } from './WithQnA';
import { VerticalWithList } from './VerticalWithList';
import { HorizontalWithMedia } from './HorizontalWithMedia';

export type FeaturedBenefitBlockTypeProps = {
  block: FeaturedBenefitBlockType;
};

export const FeaturedBenefitBlock: React.FC<FeaturedBenefitBlockTypeProps> = ({ block }) => {
  const { layoutVariant } = block;

  switch (layoutVariant) {
    case LayoutVariant.HORIZONTAL:
      return (
        <FullWidthWrapper>
          <HorizontalWithMedia block={block} />
        </FullWidthWrapper>
      );
    case LayoutVariant.FAQ:
      return (
        <SmallContainer>
          <WithQnA block={block} />
        </SmallContainer>
      );

    case LayoutVariant.VERTICAL:
      return (
        <FullWidthWrapper>
          <VerticalWithList block={block} />
        </FullWidthWrapper>
      );

    default:
      return null;
  }
};

const FullWidthWrapper = styled.div`
  position: relative;
  right: 50%;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  width: 100vw;
`;

const SmallContainer = styled.div`
  position: relative;
  margin-left: -38px;
  margin-right: -38px;
  @media (max-width: ${breakpoints.s}) {
    margin: 0;
  }
`;
